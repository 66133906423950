import * as React from "react";
import {
  IconButton,
  Grid,
  FormControl,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { SearchFieldComponent } from "../../components/form_components/search_field";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { EmaillableTwo } from "../../styles/loginstyles";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/common_styles.css";
import OrderModalComponent from "../../components/order_modal_component";
import { useLocation } from "react-router-dom";
import { TableComponent } from "../../components/table_component";
import { HFPagination } from "../../components/pagination";
import { TableCellComponent } from "../../components/table_cell_component";
import { SuccessAlert } from "../../components/alert";
import { ErrorAlert } from "../../components/alert";
import { tableHeadCell } from "../../styles/common_styles";
import { ManagementServices } from "../../services/management_services";
import { Loader } from "../../components/loader_component";
import { tableParentHolderr } from "../../styles/common_styles";

export const Consumerorders = () => {
  const location = useLocation();
  const userId = location.state?.userId;
  const moduleType = location.state?.module;
  const [startDate, setStartDate] = React.useState(0);
  const [endDate, setEndDate] = React.useState(0);
  const [orderId, setOrderId] = React.useState("");
  const [searchData, setSearchData] = React.useState("");
  const [orderDetails, setOrderDetails] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [loaderData, setLoaderData] = React.useState(true);
  const [orderStatus, setOrderStatus] = React.useState(null);
  const [alrtMsg, setAlrtMsg] = React.useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = React.useState(false);
  const [errorAlrtOpen, setErrorAlrtOpen] = React.useState(false);
  const [pageConfig, setPageConfig] = React.useState({
    min: 1,
    max: 10,
  });

  const [paginationData, setPaginationData] = React.useState({
    currentPage: null,
    pageCount: null,
    totalRecords: null,
    RecordsForPage: null,
  });

  const handlePaginate = async (page) => {
    try {
      setPageConfig({ ...pageConfig, min: page });
    } catch (error) {
      console.error(`Error occured:${error}`);
    }
  };

  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };

  const closeError = () => {
    setErrorAlrtOpen(false);
  };
  const handleSearchData = async (data) => {
    setSearchData(data);
    setPageConfig({ ...pageConfig, min: 1, max: 10 });
  };

  function formatCreatedAtToGMT(created_at) {
    const date = new Date(created_at);

    // Format the date to GMT string
    const gmtDateString = new Intl.DateTimeFormat("en-US", {
      timeZone: "GMT",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }).format(date);

    // Extract the date part and time part
    const [datePart, timePart] = gmtDateString.split(", ");

    return `${datePart}`;
  }

  function formatCreatedAtToGMTime(created_at) {
    const date = new Date(created_at);

    // Format the date to GMT string
    const gmtDateString = new Intl.DateTimeFormat("en-US", {
      timeZone: "GMT",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }).format(date);

    // Extract the date part and time part
    const [datePart, timePart] = gmtDateString.split(", ");

    return `${timePart}`;
  }

  const handleOpenModal = (id) => {
    setIsModalOpen(true);
    setOrderId(id);
  };

  const handleCloseModal = () => setIsModalOpen(false);
  const getConsumerOrderDetails = async () => {
    try {
      let ConsumerorderDetails;
      let CheforderDetails;
      if (moduleType === 1 || moduleType === "1") {
        const payload = {
          consumerId: userId,
          orderStatus: orderStatus,
          min: pageConfig.min,
          max: pageConfig.max,
          fromdate: startDate,
          todate: endDate,
          search: searchData,
        };
        ConsumerorderDetails = await ManagementServices.getConsumerOrderDetails(
          payload
        );
        if (ConsumerorderDetails.status === 1) {
          const {
            data: {
              currentPage,
              pageSize,
              totalPages,
              totalOrders,
              orderDetails,
            },
          } = ConsumerorderDetails;
          setPaginationData({
            ...paginationData,
            currentPage: currentPage,
            RecordsForPage: pageSize,
            totalRecords: totalOrders,
            pageCount: totalPages,
          });
          setOrderDetails(orderDetails);
          // setAlrtMsg(ConsumerorderDetails.message);
          // setSuccessAlrtOpen(true)
          setLoaderData(false);
        } else {
          setAlrtMsg(ConsumerorderDetails.message);
          setErrorAlrtOpen(true);
        }
      } else {
        const payload = {
          chefId: userId,
          orderStatus: orderStatus,
          min: pageConfig.min,
          max: pageConfig.max,
          fromdate: startDate,
          todate: endDate,
          search: searchData,
        };
        CheforderDetails = await ManagementServices.getChefOrderDetails(
          payload
        );
        if (CheforderDetails.status === 1) {
          const {
            data: {
              currentPage,
              pageSize,
              totalPages,
              totalOrders,
              orderDetails,
            },
          } = CheforderDetails;
          setPaginationData({
            ...paginationData,
            currentPage: currentPage,
            RecordsForPage: pageSize,
            totalRecords: totalOrders,
            pageCount: totalPages,
          });
          setOrderDetails(orderDetails);
          // setAlrtMsg(CheforderDetails.message);
          // setSuccessAlrtOpen(true);
          setLoaderData(false);
        } else {
          setAlrtMsg(CheforderDetails.message);
          setErrorAlrtOpen(true);
        }
      }
    } catch (error) {
      console.error(`Error occured while fetching roles:${error}`);
    }
  };

  const getOrderStatusNameAndColor = (status) => {
    switch (status) {
      case 0:
        return { name: "Pending", color: "orange" };
      case 1:
        return { name: "Accept", color: "green" };
      case 2:
        return { name: "Reject", color: "red" };
      case 3:
        return { name: "Start", color: "blue" };
      case 4:
        return { name: "Prepared", color: "purple" };
      case 5:
        return { name: "Ready to deliver/pickup", color: "cyan" };
      case 6:
        return { name: "Delivered", color: "lime" };
      case 7:
        return { name: "Cancelled", color: "gray" };
      case 8:
        return { name: "Missed(AutoCancel)", color: "brown" };
      default:
        return { name: "", color: "black" };
    }
  };

  let tableHeaders;

  if (moduleType === 1 || moduleType === "1") {
    tableHeaders = [
      "S no",
      "Order Id",
      "Chef Name",
      "Order Date",
      "Order Time",
      "Delivery Date",
      "Delivery Time",
      "Status",
      "Actions",
    ];
  } else {
    tableHeaders = [
      "S no",
      "Order Id",
      "Consumer Name",
      "Order Date",
      "Order Time",
      "Delivery Date",
      "Delivery Time",
      "Status",
      "Actions",
    ];
  }

  const TableHeaderRow = tableHeaders.map((row, index) => (
    <TableCell key={index} sx={tableHeadCell}>
      {row}
    </TableCell>
  ));

  const TableBody = orderDetails?.map((data, index) => {
    return (
      <TableRow key={data.id}>
        <TableCellComponent
          value={
            (paginationData.currentPage - 1) * paginationData.RecordsForPage +
            1 +
            index
          }
          indexColoumn={true}
          // sx={{ml:10}}
        />
        <TableCellComponent value={data.order_id} />
        <TableCellComponent
          value={
            moduleType === 1 || moduleType === "1"
              ? data.chef_name
              : data.consumer_name
          }
        />
        <TableCellComponent
          value={formatCreatedAtToGMT(data.payment_date) || "N/A"}
        />
        <TableCellComponent
          value={formatCreatedAtToGMTime(data.payment_date) || "N/A"}
        />
        <TableCellComponent
          value={
            data.delivery_date !== null
              ? formatCreatedAtToGMT(data.delivery_date) || "N/A"
              : "N/A"
          }
        />
        <TableCellComponent
          value={
            data.delivery_date !== null
              ? formatCreatedAtToGMTime(data.delivery_date) || "N/A"
              : "N/A"
          }
        />
        <TableCell
          sx={{
            color: getOrderStatusNameAndColor(data.order_status).color,
            fontSize: "16px",
            textAlign: "center",
          }}
        >
          {getOrderStatusNameAndColor(data.order_status).name}{" "}
        </TableCell>
        <TableCell>
          <IconButton onClick={() => handleOpenModal(data.id)}>
            <VisibilityIcon sx={{ color: "black" }} />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  });

  const handlestatusChange = (event) => {
    setOrderStatus(event.target.value);
  };

  React.useEffect(() => {
    getConsumerOrderDetails();
  }, [pageConfig.min, searchData, startDate, orderStatus, endDate]);

  return (
    <>
      {loaderData ? (
        <Loader />
      ) : (
        <>
          <Grid
            container
            spacing={2}
            justifyContent={"end"}
            alignItems={"end"}
            alignContent={"baseline"}
            mb={2}
            sx={{ height: "10vh" }}
          >
            <Grid item md={3}>
              <SearchFieldComponent searchText={handleSearchData} />
            </Grid>
            <Grid item md={1.6}>
              <TextField
                type="date"
                size="small"
                InputProps={{
                  placeholder: "startDate",
                }}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              />
            </Grid>
            <Grid sx={{ mb: 1.5, ml: 2, fontWeight: "bold" }}>To</Grid>
            <Grid item md={1.6}>
              <TextField
                type="date"
                size="small"
                InputProps={{
                  placeholder: "endDate",
                }}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
              />
            </Grid>
            <Grid item md={1.8}>
              <FormControl fullWidth>
                <Grid sx={EmaillableTwo}></Grid>
                <Select
                  value={orderStatus}
                  size={"small"}
                  displayEmpty
                  inputProps={{ "aria-label": "Order status" }}
                  onChange={(e) => handlestatusChange(e)}
                >
                  <MenuItem value="" disabled>
                    Order status
                  </MenuItem>
                  <MenuItem value={null}>All</MenuItem>
                  <MenuItem value={0}>Pending</MenuItem>
                  <MenuItem value={7}>Cancelled</MenuItem>
                  <MenuItem value={8}>Missed(AutoCancel)</MenuItem>
                  <MenuItem value={6}>Delivered</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid sx={tableParentHolderr}>
            <TableComponent headerData={TableHeaderRow} bodyData={TableBody} />
            <HFPagination details={paginationData} paginate={handlePaginate} />
          </Grid>
          <OrderModalComponent
            openhandler={isModalOpen}
            handleClose={handleCloseModal}
            orderId={orderId}
          />
          <SuccessAlert
            alertMsg={alrtMsg}
            open={successAlrtOpen}
            close={closeSuccess}
          />
          <ErrorAlert
            alertMsg={alrtMsg}
            open={errorAlrtOpen}
            close={closeError}
          />
        </>
      )}
    </>
  );
};
